import { css } from "@emotion/react"

export const section = css`
  background-color: #F8F8F8;
  font-family: 'Lato', sans-serif;
  padding-top: 136px;
  padding-bottom: 112px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 18px;
    padding-right: 18px;
  }
`

export const section2 = css`
  background-color: #5416FF;
  font-family: 'Lato', sans-serif;
  padding-top: 136px;
  padding-bottom: 112px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 18px;
    padding-right: 18px;
  }
`

export const section3 = css`
  background-color: #F8F8F8;
  font-family: 'Lato', sans-serif;
  padding-top: 136px;
  padding-bottom: 0px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 0px;
    padding-left: 18px;
    padding-right: 18px;
  }
`

export const section4 = css`
  background-color: #F8F8F8;
  font-family: 'Lato', sans-serif;
  padding-top: 136px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 0px;
    padding-left: 18px;
    padding-right: 18px;
  }
`

export const sliderSection = css`
  background-color: #5416FF;
  font-family: 'Lato', sans-serif;
  padding-top: 12px;
  padding-bottom: 0px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 0px;
    padding-left: 18px;
    padding-right: 18px;
  }
`

export const HeaderBanner = css`
  background: #F8F8F8;
  margin-top: 112px;
  @media (max-width: 480px) {
    margin-top: 112px;
  }
`

export const HeaderBanner2 = css`
  background: #F8F8F8;
  @media (max-width: 480px) {
    margin-top: 112px;
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1086px;
`

export const container2 = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 0px;
	padding: 0px;
  /* flex: 1;
	margin: 2px;
	padding: 10px; */
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 0px !important;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  font-family: 'Lato', sans-serif;
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #5416FF;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 72px;
  }
  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 48px;
    width: 90%;
  }
  h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 6px;
    margin-top: 16px;
    width: 90%;
    max-width: 800px;
    span {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #808080;
      font-weight: normal;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    /* max-width: 800px; */
    width: 90%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #808080;
    span {
      color: #5416FF;
      font-weight: bold;
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: baseline;
      img {
        margin: 0px;
        margin-right: 16px;
      }
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  img {
    margin-bottom: 36px;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
      margin-bottom: 32px;
    }
    img {
      margin-top: 0px;
      margin-bottom: 36px;
      width: 100%;
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }
`

export const content2 = css`
  font-family: 'Lato', sans-serif;
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #FFC208;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 72px;
  }
  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 48px;
    width: 100%;
    max-width: 800px;
  }
  h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 6px;
    margin-top: 16px;
    width: 100%;
    max-width: 800px;
    span {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #808080;
      font-weight: normal;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #F8F8F8;
    span {
      color: #5416FF;
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: baseline;
      img {
        margin: 0px;
        margin-right: 16px;
      }
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  img {
    margin-top: 36px;
    :not(:last-child) {
      margin-bottom: 72px;
    }
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
      margin-bottom: 32px;
    }
    img {
      margin-top: 0px;
      margin-bottom: 36px;
      width: 100%;
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }
`

export const content3 = css`
  font-family: 'Lato', sans-serif;
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #5416FF;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 72px;
  }
  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 48px;
    width: 100%;
    max-width: 800px;
  }
  h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #5416FF;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 6px;
    margin-top: 16px;
    width: 100%;
    max-width: 800px;
    span {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #808080;
      font-weight: normal;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #2E2D2C;
    span {
      color: #5416FF;
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: baseline;
      img {
        margin: 0px;
        margin-right: 16px;
      }
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  img {
    margin-top: 36px;
    :not(:last-child) {
      margin-bottom: 72px;
    }
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
      margin-bottom: 32px;
    }
    img {
      margin-top: 0px;
      margin-bottom: 36px;
      width: 100%;
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`

export const sliderDesktop = css`
  @media (max-width: 480px) {
    display: none;
  }
`

export const mb32 = css`
  @media (max-width: 480px) {
    margin-bottom: 32px;
  }
`

export const dotsImage = css`
  width: auto;
  @media (max-width: 480px) {
    width: 60%;
  }
`

export const box1 = css`
  width: 100%;
  max-width: 526px;
  background: #5416FF;
  border: 1px solid #5416FF;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  ul {
    li {
      display: flex;
      align-items: baseline;
      img {
        margin: 0px;
        margin-right: 16px;
        margin-bottom: 0px !important;
      }
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #F8F8F8 !important;
    span {
      color: #5416FF;
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
`

export const box2 = css`
  width: 100%;
  background: #5416FF;
  border: 1px solid #5416FF;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  ul {
    li {
      display: flex;
      align-items: baseline;
      img {
        margin: 0px;
        margin-right: 16px;
        margin-bottom: 0px !important;
      }
      :not(:last-child) {
        margin-bottom: 36px;
      }
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #F8F8F8 !important;
    span {
      color: #5416FF;
    }
    :not(:last-child) {
      margin-bottom: 36px;
    }
  }
`

export const contentSlider = css`
  font-family: 'Lato', sans-serif;
  max-width: 410px;
  width: 100%;
  margin: auto;
  h2 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #FFC208;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
  }
  h3 {
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #FFC208;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 48px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
  }
  @media (max-width: 480px) {
    margin-bottom: 200px;
    h2 {
      margin-top: 42px;
      font-size: 60px;
      line-height: 48px;
    }
  }
`

export const calendarImage = css`
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const sizeDiv = css`
  width: 800px;
  @media (max-width: 480px) {
    display: none;
  }
`

export const bottomImg = css`
  background-color: #F8F8F8;
  text-align: right;
  margin-top: -290px;
  margin-bottom: -4px;
  @media (max-width: 480px) {
    text-align: center;
    margin-top: 0;
    img {
      width: 50% !important;
    }
  }
`

export const bottomImg2 = css`
  background-color: #F8F8F8;
  text-align: right;
  margin-top: -400px;
  margin-bottom: -4px;
  @media (max-width: 480px) {
    text-align: center;
    margin-top: 0;
    img {
      width: 30% !important;
      margin-top: 72px;
    }
  }
`

export const footerLp = css`
  background-color: #EAEAEA;
  font-family: 'Montserrat';
  padding: 60px 0;
  text-align: center;
  img {
    margin-bottom: 32px;
  }
  p {
    color: #2D3436;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
  }
`